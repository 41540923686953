.list-group {
  &-item {
    &__action {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border: 0;
      width: 100%;
      background: transparent;
      border-radius: inherit;
      cursor: pointer !important;
      &:hover,
      &.hover {
        background-color: rgba($primary, 0.15);
      }
    }
    > .row {
      position: relative;
      // z-index: 10; //fjernet pga dropdown ligger sig under den dropdown i sibling list-item
      pointer-events: none;
      button,
      a {
        pointer-events: all;
      }
      h4 {
        font-size: 1.15em;
        font-weight: 600;
      }
    }
    &--card {
      margin-bottom: 0.75em;
      border: 0;
      border: 1px solid $grey !important;
      // border-radius: 0.5em !important;
      &:hover,
      &.hover {
        border-color: $primary !important;
        cursor: pointer !important;
      }
    }
  }
}
