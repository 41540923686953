/**
 * Bootstrap settings
 * (find mulige settings i boostrap .variables under node_modules>bootstrap>scss>_variables.scss)
 */

// Standard Color Maps
$primary: #02818f;
$secondary: #e5f2f4;
// $warning: #e29c4b;
$warning: #af6419;
// $danger: #e25f5f;
$danger: #cf2e2d;
// $success: #028f38;
$success: #03742d;
$dark: #323232;
// $light: #f8f8f8;
$light: #f9f9f9;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "warning": $warning,
  "danger": $danger,
  "success": $success,
  "dark": $dark,
  "light": $light,
);

// Custom colors
$white: white;
// $grey: shade-color($light, 6%);
$grey: #dfdfdf;
// Personer
// $indberetter: #020202;
// $indberetter: $primary;
$polygonhover: #3057ed;
$indberetter: #2fa369;
$horing: #9a3dc9;
$horingpart: $horing;
// $horingsansvarlig: green;
$horingsansvarlig: #b35f35;
$sagsbehandler: $warning;
$internnote: $sagsbehandler;

$custom-colors: (
  "white": $white,
  "grey": $grey,
  "indberetter": $indberetter,
  "horing": $horing,
  "horingpart": $horingpart,
  "horingsansvarlig": $horingsansvarlig,
  "sagsbehandler": $sagsbehandler,
  "internnote": $internnote,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

/* ***** Font Sizes ***** */
// $font-size-base: 0.95em;

// $h1-font-size: $font-size-base * 1.75;
// $h2-font-size: $font-size-base * 1.5;
// $h3-font-size: $font-size-base * 1.25;
// $h4-font-size: $font-size-base * 1.15;
// $h5-font-size: $font-size-base * 1;
// $h6-font-size: $font-size-base;

// // Headings
// $headings-font-weight: 800;
// $headings-line-height: 1.5;

/* ***** Utilities ***** */

//Opacity
// $utilities: (
//   "opacity": (
//     property: opacity,
//     responsive: true,
//     values: (
//       0: 0,
//       25: 0.25,
//       50: 0.5,
//       75: 0.75,
//       100: 1,
//     ),
//   ),
// );

//container sizes
$container-max-widths: (
  sm: 540px,
  md: 720px,
  // lg: 700px,
  // xl: 700px,
  // xxl: 700px,,,,,,,,,,,,,,,,,,,,,,,,,
);

/* ***** General styling ***** */
// General
$enable-negative-margins: true;
$border-radius: 0;

// Forms
$input-border-color: $grey;
// $form-check-input-border: 1px solid rgba($grey, 0.25);
$form-check-input-border: 1px solid $grey;
// $list-group-border-color: rgba($grey, 0.125);
$list-group-border-color: $grey;
// $card-border-color: rgba($grey, 0.125);
$card-border-color: $grey;

//spacers
$spacer: 1.25rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
);
// $negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);
