.dropdown {
  &-menu {
    .dropdown-item {
      > svg.bi {
        width: 16px;
        height: auto;
      }
    }
  }
}
