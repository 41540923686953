// Zoom component
// .c-zoom {
//   border-radius: $border-radius;
//   .btn {
//     border-radius: $border-radius;
//     &:first-child {
//       border-bottom-left-radius: 0 !important;
//       border-bottom-right-radius: 0 !important;
//     }
//     &:last-child {
//       border-top-left-radius: 0 !important;
//       border-top-right-radius: 0 !important;
//     }
//     .ember-view {
//       > svg {
//         height: 2em !important;
//         width: auto !important;
//       }
//     }
//   }
// }

//Ol Zoom component
.ol-control {
  position: absolute;
  top: unset !important;
  left: unset !important;
  right: $spacer;
  bottom: #{$spacer + 1.5};
  background-color: transparent !important;
  padding: 0 !important;
  box-shadow: $box-shadow;
  @include media-breakpoint-down(md) {
    display: none;
  }
  > button {
    font-size: 1.7rem !important;
    height: 1.2em !important;
    width: 1.2em !important;
    font-weight: 500 !important;
    background-color: white !important;
    color: $dark !important;
    margin: 0 !important;
    border-radius: $border-radius !important;
    &:first-child {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-bottom: 1px solid tint-color($dark, 85%) !important;
    }
    &:last-child {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    &:hover {
      background: tint-color($primary, 90%) !important;
      color: $primary !important;
    }
    &:focus {
      border-color: #81c0c7;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(2 129 143 / 25%);
      z-index: 99;
      position: relative;
    }
  }
}
