.c-avatar {
  align-items: center;
  //   border-radius: $border-radius;
  border-radius: 0.5em;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  font-weight: 500;
  border: 0;

  background-color: tint-color($dark, 90%);

  height: 2.75em;
  min-width: 2.75em;
  width: 2.75em;

  font-size: 16px;

  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      background-color: tint-color($value, 90%) !important;
      //   color: text-color($value) !important;
      color: $value !important;
    }
  }

  &--text {
    font-weight: 700;
  }
  svg {
    width: 1.25em;
    height: auto;
  }
}
