.card {
  &-title {
    font-size: 1.25em;
    font-weight: 300;
    margin-bottom: 0.5em;
    display: block;
  }
  &--sdfe {
    position: relative;
    border-bottom: 0;
    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: -1px;
      right: -1px;
      background-color: $primary;
      height: 5px;
    }
  }
}
