.c-layers {
  background-color: $white;
  width: 265px;
  @include media-breakpoint-down(md) {
    width: auto;
    max-width: 100%;
  }
  &__title {
    font-weight: 600;
  }
  &__head {
    position: relative;
    z-index: 25;
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
  &__content {
    overflow-y: auto;
    @include media-breakpoint-down(md) {
      max-height: calc(100vh - 13.5rem);
    }
  }
  &__section {
    padding: 0 $spacer;
    margin-bottom: $spacer;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: $spacer;
    }
    .form-check {
      padding-right: 1.25em;
      &.is-loading {
        .form-check-label {
          opacity: 0.5;
        }
        &:after {
          color: $primary;
          content: "";
          position: absolute;
          right: $spacer;
          display: inline-block;
          width: 1rem;
          height: 1rem;
          vertical-align: -0.125em;
          border: 0.2em solid currentColor;
          border-right-color: transparent;
          border-radius: 50%;
          -webkit-animation: 0.75s linear infinite spinner-border;
          animation: 0.75s linear infinite spinner-border;
        }
      }
    }
  }
  &.is-active {
    @include media-breakpoint-down(md) {
      width: calc(100% - #{$spacer * 2});
    }
  }
}
