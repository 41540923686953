@use "sass:math";
.c-breadcrumbs {
  display: flex;
  &__container {
    display: flex;
    align-items: center;
    z-index: 36;
    position: relative;
    float: left;
    background: transparent;
    font-size: 12px;
    line-height: 1.4;
    min-height: 32px;
    &:before {
      content: "\0020";
      display: inline-block;
      background: #00818f;
      height: 100%;
      width: 9999px;
      position: absolute;
      top: 0;
      left: -9999px;
    }
    &:after {
      content: "\0020";
      display: inline-block;
      height: 20px;
      width: 20px;
      background: #fff;
      position: absolute;
      left: -30px;
      top: 6px;
      border-radius: 50%;
    }
    .breadcrumb-item + .breadcrumb-item {
      &:before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-slash' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 4.646a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
        width: 1em;
        height: 1em;
        display: inline-block;
        margin: 0 0.5em 0 -0.5em;
        padding: 0;
      }
    }
    .breadcrumb {
      margin-bottom: 0;
      margin-left: math.div($spacer, 2);
      &-item {
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
