.nav {
  &--scroll {
    overflow-x: auto;
    flex-wrap: initial;
    border-left: 1px solid $primary;
    border-right: 1px solid $primary;
    @include media-breakpoint-down(sm) {
      border-top: 1px solid $primary;
      overflow: initial !important;
      display: block;
    }
    button {
      white-space: nowrap;
      @include media-breakpoint-down(sm) {
        width: 100%;
        display: block;
        margin: 0 !important;
        border-left: 0;
        border-right: 0;
        text-align: center;
        border-top: 0;
      }
      &:last-child {
        border-right: 0;
      }
      &:first-child {
        border-left: 0;
      }
    }
  }
}
