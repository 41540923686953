.form {
  &-label {
    font-weight: 600;
    margin-bottom: 0.35rem;
  }
}

//upload form
.form-control[type="file"] {
  border: 1px solid tint-color($primary, 75%);
  color: $primary;
  &::-webkit-file-upload-button {
    background-color: $primary;
    color: white;
  }
}

.form-control {
  &-sm {
    border-radius: $border-radius;
  }
}

// expand form
.form-expand {
  > .btn {
    padding: 0;
    text-align: left;
    border: 0;
    font-size: 1em;
    span {
      // background-color: red;
      // padding: 0.2em;
      // margin-right: 0.15em;
      margin-right: 0.45em;
      svg {
        height: 1em;
        width: auto;
        margin-top: -0.25em;
      }
    }
  }
  .collapse {
  }
}
