.l-toast {
  position: fixed;
  bottom: $spacer;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  // display: flex;
  display: grid;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  .toast {
    pointer-events: all;
    margin-bottom: 0.5em;
    font-size: 1rem;
    &-body {
      padding: 1.15em;
    }
    .btn-close {
      padding: 0 0.5em 1.15em 1.15em;
    }
  }
}
