.c-search {
  svg {
    color: tint-color($dark, 35%);
    position: absolute;
    // z-index: 9999999999999;
    right: 1.25em;
    top: 50%;
    transform: translateY(-50%);
  }
  .form-control {
    padding-right: 2.75em !important;
    &::-webkit-search-cancel-button {
      display: block;
      width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(150, 150, 150)' class='bi bi-x-circle-fill' viewBox='0 0 16 16'%3E%3Cpath d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'/%3E%3C/svg%3E");
      background-size: contain;
      appearance: none;
      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(50, 50, 50)' class='bi bi-x-circle-fill' viewBox='0 0 16 16'%3E%3Cpath d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'/%3E%3C/svg%3E");
      }
    }
    &:hover,
    &:focus {
      & + svg {
        color: shade-color($dark, 15%);
        display: block;
      }
    }
  }
  .dropdown-menu {
    border: 0;
    width: 100%;
    box-shadow: $box-shadow;
    border-top: 1px solid shade-color($light, 10%) !important;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    .dropdown-item {
      width: 100% !important;
      &:active {
        background-color: $primary !important;
      }
    }
  }
}
