@use "sass:math";
.ol-scale-line {
  background-color: transparent;
  bottom: 0;
  right: 0;
  padding: 0;
  &-inner {
    color: $dark;
    font-weight: 600;
    border: 2px solid $dark;
    border-top: 0;
    height: 1.5em;
    line-height: 0.75em;
    @include media-breakpoint-down(md) {
      height: 1em;
      line-height: 0.15em;
    }
  }
  //specic KLAK (hvis controlen placeres i div .c-scaleline skal dette fjernes)
  position: absolute;
  bottom: #{$spacer + 1.5};
  right: #{($spacer * 1.5) + 2.5rem};
  left: unset;
  @include media-breakpoint-down(md) {
    right: 0;
    // left: #{($spacer * 2) + 4rem};
    left: $spacer;
    bottom: math.div($spacer, 3);
  }
}
