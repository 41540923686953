body {
  margin: 0;
}

#main-content {
  position: relative; //bugfix
}

.App {
  height: 100vh;
}

.demo-user {
  position: fixed;
  top: 0.25rem;
  left: 0.25rem;
  height: 115px;
  display: inline-grid;
  align-items: center;
  padding: 0.45em 0.85em;
  border-radius: 0.25em;
  background-color: rgba(50, 50, 50, 0.8);
  z-index: 9999;
  h6 {
    margin-right: 0.75em;
    margin-bottom: 0;
    font-weight: 400;
    color: white;
  }
  .dropdown {
    .btn {
      padding: 0.45em 1.25em;
    }
  }
}

.rotate-45 {
  transform: rotate(45deg);
}

.c-switch-map {
  z-index: 80;
  width: auto;
  position: absolute;
  bottom: #{$spacer * 1.25};
  right: #{$spacer * 1.25};
  height: 2.75em;
  width: 2.75em;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  svg {
    width: 1.25em;
    height: 1.25em;
    margin-top: -3px;
  }
}

.c-upload-file {
  position: relative;
  label {
    @extend .btn;
    @extend .btn-primary;
  }
  input {
    // display: none; //skjules nu med klassen .visually-hidden
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: inherit;
    width: inherit;
    &:focus {
      border: 5px solid red;
    }
  }
}

.c-edit-draw {
  position: absolute;
  bottom: $spacer;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 0.5em;
  padding: 1em;
}

.c-logo {
  font-size: 1rem;
  display: flex;
  align-items: center;
  > svg {
    height: 3.25em;
    width: auto;
  }
  &__tekst {
    font-size: 1em;
    line-height: 1.45;
    margin-bottom: -0.75em;
    > strong {
      text-transform: uppercase;
      letter-spacing: 0.04em;
      font-weight: 700;
    }
    > span {
    }
    > span,
    > strong {
      font-size: 0.875em;
      color: $dark;
      display: block;
      margin: 0;
      padding: 0;
      word-break: break-all;
      white-space: normal;
    }
  }
}

.c-login-button {
  @include media-breakpoint-down(lg) {
    margin-top: 0.75em !important;
    width: 100% !important;
    border: 0;
    font-size: 1.25em;
    padding-top: 0.75em !important;
    padding-bottom: 0.75em !important;
    height: auto !important;
  }
  svg {
    @include media-breakpoint-down(lg) {
      margin-top: -2px;
    }
  }
}
