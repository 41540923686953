.fw-semi-bold {
  font-weight: 600;
}

.bg {
  //tint BG: .bg-tint-primary
  @each $color, $value in $theme-colors {
    &-tint-#{$color} {
      background-color: tint-color($value, 90%) !important;
    }
  }
}

.text {
  //tint BG: .bg-tint-primary
  @each $color, $value in $theme-colors {
    &-shade-#{$color} {
      color: shade-color($value, 15%) !important;
    }
  }
}

ul.list-arrows {
  @include media-breakpoint-down(sm) {
    padding-left: 1.5em;
  }
  li {
    list-style: none;
    margin-bottom: 0.25em;
    &:before {
      content: "";
      display: inline-block;
      // height: 1em;
      // width: 1em;
      height: 0.7em;
      width: 0.5em;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4.652' height='7.78' viewBox='0 0 4.652 7.78'%3E%3Cg id='Path_551' data-name='Path 551' transform='translate(-7.903 -6.01)'%3E%3Cpath id='Path_833' data-name='Path 833' d='M12.33,10.43,9.21,13.56a.759.759,0,0,1-.53.23H8.66a.734.734,0,0,1-.52-.21.77.77,0,0,1-.03-1.08l2.61-2.6L8.13,7.31A.764.764,0,0,1,9.21,6.23l3.12,3.13A.749.749,0,0,1,12.33,10.43Z' fill='currentColor'/%3E%3C/g%3E%3C/svg%3E%0A");
      margin-right: 0.85em;
      margin-left: -1.15em;
      background-size: contain;
      background-repeat: no-repeat;
      // background-color: rgba(255, 0, 0, 0.25);
      background-position: center center;
    }
    &:last-child {
      margin-bottom: 0;
    }
    a,
    button {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      @include media-breakpoint-down(sm) {
        text-align: left;
        padding: 0 0.15em;
      }
    }
    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: baseline;
      margin-bottom: 0.75em;
    }
  }
}
