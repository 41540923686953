.c-drawtools {
  position: absolute;
  bottom: $spacer;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 0.5em;
  .btn {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0.25em;
    width: 6em;
    border: 0;
    border-radius: 0.25em !important;
    &:after {
      content: "";
      position: absolute;
      top: 15%;
      bottom: 15%;
      right: -1px;
      width: 2px;
      border-radius: 1em;
      background-color: $grey;
    }
    &:first-child {
      // border-top-left-radius: 0.25em;
      // border-bottom-left-radius: 0.25em;
    }
    &:last-child {
      // border-top-right-radius: 0.25em;
      // border-bottom-right-radius: 0.25em;
      &:after {
        content: none;
      }
    }
    div {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      height: 2em;
      //   margin-bottom: 0.05em;
      svg {
      }
    }
    span {
      display: block;
      width: 100%;
      font-size: 11px;
      margin-top: auto;
      margin-bottom: 0.05em;
      font-weight: 600;
    }
    &:hover:not(.active) {
      color: shade-color($primary, 15%);
      background-color: rgba($primary, 0.15);
    }
    &.active {
      //active
      &::after {
        content: none;
      }
    }
  }
}
