.c-attribution {
  background-color: rgba(shade-color($light, 5%), 0.75);
  // border-radius: #{$border-radius * 1.5} 0 0 0;
  border-radius: 0.5em 0 0 0;
  font-size: 0.7em;
  font-weight: 300;
  padding: 0.25em 1em;
  color: $dark;
  a {
    font-weight: 400;
    text-decoration: underline;
    color: $dark;
  }
}
