.l-section {
  // background-color: orange;
  margin: #{$spacer * 3} 0;
  &--disabled {
    opacity: 0.5;
  }
}

.l-section-wrapper {
  :first-child.l-section {
    margin-top: 0;
  }
  :last-child.l-section {
    margin-bottom: 0;
  }
}

.l-section-wrapper.form-control.is-invalid,
.l-section-wrapper.form-control.is-valid {
  background-position: right calc(0.375em + 0.1875rem) top
    calc(0.375em + 0.1875rem);
}
