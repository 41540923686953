.l-map {
  position: relative;
  width: 100%;
  height: 100%;
  &__ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    > * {
      pointer-events: all;
    }
    .c-search {
      position: absolute;
      top: #{$spacer * 2};
      left: #{$spacer * 1.5};
      z-index: 50;
      @include media-breakpoint-down(md) {
        top: #{$spacer * 1};
        left: #{$spacer * 1};
        right: #{$spacer * 1};
      }
      input {
        @include media-breakpoint-down(md) {
          width: 100% !important;
        }
      }
    }
    .c-messages {
      position: absolute;
      top: #{($spacer * 3) + 2.75rem};
      left: #{$spacer * 1.5};
    }
    .c-layers {
      position: absolute;
      top: #{$spacer * 2};
      right: #{$spacer * 1.5};
      &__content {
        max-height: calc(100vh - #{$spacer * 6.45});
      }
      @include media-breakpoint-down(xl) {
        top: auto;
        right: auto;
        // left: #{$spacer * 1};
        // top: #{$spacer * 1};
        top: 6em;
        left: #{$spacer * 1.5};
        .c-layers__content {
          max-height: calc(100vh - 11.0625rem);
        }
      }
      @include media-breakpoint-down(md) {
        top: auto;
        right: auto;
        // left: #{$spacer * 1};
        // top: #{$spacer * 1};
        top: 4.5em;
        right: $spacer;
      }
    }
    .c-attribution {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  &.has-focus {
    .l-map__ui {
      box-shadow: inset 0 0 0 0.25rem rgba($primary, 0.5);
    }
  }
}
