@use "sass:math";

.c-modal-backdrop {
  z-index: 3000;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba($dark, 0.75);

  .c-modal {
    position: fixed;
    z-index: 400;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-lg;
    width: calc(100% - 5rem);
    max-width: 40rem;
    max-height: 36rem;
  }

  .c-modal-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: inherit;
    height: 100%;
    max-height: 36rem;
  }

  .c-modal-header {
    padding: #{$spacer * 1.5};
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    .c-modal-close {
      align-self: flex-start;
      align-items: center;
      // margin: 0 0 0 (math.div($spacer, 10));
      line-height: 1.29 !important;
      svg {
        height: 16px;
        width: 16px;
      }
    }
    p {
      float: right;
    }
  }
  .c-modal-body {
    border-radius: inherit;
    padding: 0 #{$spacer * 1.5} #{$spacer * 1.5};
    height: 100%;
    overflow-y: auto;
  }
}
