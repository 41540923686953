@use "sass:math";
.btn {
  // padding: #{$spacer / 1.75} #{$spacer * 1.75};
  padding: math.div($spacer, 2.5) #{$spacer * 1};
  &-lg {
    padding: math.div($spacer, 1.75) #{$spacer * 1.75};
    border-radius: 0;
  }
  &-icon {
    font-size: 1.7rem;
    height: 1.2em;
    width: 1.2em;
    padding: 0 !important;
    color: $dark;
    line-height: 0;
    border: 3px solid white;
    &.active {
      background-color: $primary;
      color: white;
    }
    &.btn-lg {
      height: 1.75em;
      width: 1.75em;
    }
  }
  &-link {
    &-icon {
      height: 1.25em;
      width: 1.25em;
      margin: -0.25em;
    }
  }
}
