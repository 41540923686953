.navbar {
  &-brand {
    @include media-breakpoint-down(lg) {
      font-size: 1em;
    }
    @include media-breakpoint-down(sm) {
      font-size: 0.75em;
    }
  }
  &-nav {
    // @include media-breakpoint-up(lg) {
    //   background-color: red;
    // }
    .nav-item {
      .nav-link {
        color: tint-color($dark, 33%);
        border: 0;
        background-color: initial;
        font-weight: 500;
        @include media-breakpoint-down(lg) {
          font-size: 1.25em;
        }
        &.active {
          color: $dark;
          background-color: initial;
          position: relative;
          font-weight: 500;
          @include media-breakpoint-down(lg) {
            color: $primary;
            background-color: tint-color($primary, 90%);
          }
          &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0.5rem;
            right: 0.5rem;
            // width: 100%;
            height: 2px;
            background: $primary;
            border-radius: $border-radius;
            @include media-breakpoint-down(lg) {
              content: none;
            }
          }
        }
      }
    }
  }
}
