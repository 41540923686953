.c-metadata-list {
  //dl
  dt {
    //label
    font-weight: 500;
    // color: red;
  }
  dd {
    //indhold
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0.5em;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
