@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$font-pitch: "Roboto", sans-serif;
$font-standard: $font-pitch;
$font-display: $font-standard;

.fw-semibold {
  font-weight: 500 !important;
}

body,
html {
  font-family: $font-standard;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-pitch;
  font-weight: 300;
}

h2,
.h2 {
  font-size: 1.5em;
}

.font-display {
  font-family: $font-display;
}

p {
  line-height: 1.65;
  color: tint-color($dark, 20%);
  &.cite {
    font-family: $font-pitch;
    font-style: italic;
    line-height: 1.5;
  }
  &.lead {
    // font-size: 1.175em;
    font-size: 1.35em;
    font-weight: 400;
    line-height: 1.75;
    color: tint-color($dark, 15%);
  }
}
