.c-journal {
  position: relative;
  background-color: $light;
  border: 1px solid $grey;
  &__head {
    position: absolute;
    top: 0;
    right: 0.95em;
    left: 0.95em;
    background-image: linear-gradient(rgba($light, 1), rgba($light, 0) 130%);
    padding: 0.5em 0.5em 1em;
    text-align: center;
    z-index: 90;
    // background-image: linear-gradient(rgba($dark, 1), rgba($dark, 0));
  }
  &__content {
    padding: 2.5em 0 1em;
    height: 35em;
    overflow-y: scroll;
  }
  &__footer {
    border-top: 1px solid $grey;
  }
  &__message {
    width: 75%;
    padding: 0.85em;
    margin: 1em;
    border-radius: 0.5em;
    background-color: white;
    border: 3px solid white;
    line-height: normal;
    box-shadow: $box-shadow-sm;
    @include media-breakpoint-down(sm) {
      width: 82%;
    }
    &-header {
      margin-bottom: 0.5em;
      .geometri-color {
        border: 3px solid $indberetter; //inberetter farve
        background-color: rgba($indberetter, 0.65); //inberetter farve
        display: inline-block;
        border-radius: 0.2em;
        width: 1em;
        height: 1em;
        margin-bottom: -2px;
      }
    }
    &-content {
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
      .list-group-item--card {
        //card draw list item
        // background-color: red;
        padding: 0.5em !important;
        // border: 0 !important;
        margin-top: 0.7s5em;
        border-radius: 0.25em;
        margin-bottom: 0;

        .col {
          p.mb-0 {
            line-height: normal;
          }
        }
        .col-auto {
          display: flex;
          // align-items: center;
          &:last-child {
            // margin-right: 0.5em;
            margin-top: 0.25em;
          }
        }
        .c-avatar {
          border-radius: 0.15em;
        }
      }
    }
    &-footer {
      margin-top: 0.75em;
    }
    &--sagsbehandler,
    &--horingsansvarlig {
      background-color: tint-color($primary, 92%);
      border-color: tint-color($primary, 92%);
      // background-color: tint-color($sagsbehandler, 80%);
      // border-color: tint-color($sagsbehandler, 80%);
      .c-journal__message-content {
        > .list-group-item--card {
          background: transparent;
          border-color: rgba($primary, 0.2) !important;
          .c-avatar {
            background-color: tint-color($primary, 82%) !important;
          }
          .dropdown {
            > .btn {
              border: 0;
            }
          }
          a {
            color: shade-color($primary, 10%);
          }
          &:hover {
            border-color: rgba($primary, 1) !important;
          }
        }
      }
    }
    &--sagsbehandler {
      .geometri-color {
        border-color: $sagsbehandler;
        background-color: rgba($sagsbehandler, 0.65);
      }
    }
    &--intern-note,
    &--overdragelseskommentar,
    &--afslutningskommentar {
      // box-shadow: none;
      background-color: tint-color($primary, 90%);
      border-color: tint-color($danger, 0%);
      // background-color: rgb(255, 255, 152);
      // background-color: $internnote;
      // background-color: rgb(240, 240, 240);
      // background-color: tint-color($danger, 80%);
      // border-color: tint-color($danger, 80%);
      // border: 2px dotted tint-color($danger, 40%);
      // border-color: rgb(240, 240, 240);
      // border-color: $danger;
      // background-color: transparent;
      // border-color: tint-color($sagsbehandler, 80%);
      // border-color: $internnote;
      .c-journal__message-footer {
        span {
          color: $danger !important;
          font-size: 0.75em;
        }
      }
      .geometri-color {
        border-color: $sagsbehandler;
        background-color: rgba($sagsbehandler, 0.65);
      }
    }
    // Nicolai bruger lige den her til at tjekke at alle får en class...
    &--corrupt {
      background-color: black;
      border-color: black;
      .geometri-color {
        border-color: $horing;
        background-color: black;
      }
    }
    &--horingspart {
      // background-color: tint-color($horing, 80%);
      // border-color: tint-color($horing, 80%);
      .geometri-color {
        border-color: $horing;
        background-color: rgba($horing, 0.65);
      }
    }
    &--horingsansvarlig {
      // background-color: tint-color($horingsansvarlig, 80%);
      // border-color: tint-color($horingsansvarlig, 80%);
      // background-color: tint-color($primary, 92%);
      // border-color: tint-color($primary, 92%);
      .geometri-color {
        border-color: $horingsansvarlig;
        background-color: rgba($horingsansvarlig, 0.65);
      }
    }
    // &--overdragelseskommentar {
    //   background-color: rgb(100, 100, 100);
    //   border-color: rgb(100, 100, 100);
    // }
    // fjernes
    &--geometri,
    &--fil {
      // background-color: red;
    }
  }
  &--horing {
    background-color: tint-color($horing, 90%);
    border: 1px solid tint-color($horing, 80%);
    .c-journal__head {
      background-image: linear-gradient(
        rgba(tint-color($horing, 90%), 1),
        rgba(tint-color($horing, 90%), 0) 130%
      );
    }
    .c-journal__footer {
      border-top: 1px solid tint-color($horing, 80%);
    }
  }
}
