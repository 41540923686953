.alert {
  background-color: transparent;
  border-width: 3px;
  padding: 1.25em 1em;
  @each $color, $value in $theme-colors {
    &-#{$color} {
      border-color: tint-color($value, 90%);
      // color: $dark;
      p {
        // color: shade-color($value, 25%);
        color: rgba($dark, 0.75);
      }
      > svg {
        color: $value;
        margin-right: 0.5rem;
        height: 1.5rem;
        width: auto;
      }
      .alert-icon {
        color: $value;
      }
    }
  }
  &-icon {
    font-size: 1.5em;
    line-height: 1;
    padding-right: 0.75em;
    padding-left: 0.15em;
  }
  &-heading {
    font-size: 1.25em;
    font-weight: 500;
    line-height: normal;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
